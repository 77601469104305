import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import MediaTile from "../components/MediaTile"
import Button from "../components/Button"
import Mailchimp from "react-mailchimp-form"
import { css } from "emotion"

import headerImg from "../images/2022-02/sign-3.jpg"

const Donate = () => (
  <Layout>
    <SEO title="Support" keywords={[`support`, `Alex Riley`, `office`]} />
    <MediaTile
      backgroundImage={headerImg}      
      backgroundPosition="50% 40%"            
      color="white"      
      minHeight="500px"      
    >
      {/* <h1>Support Alex</h1> */}
    </MediaTile>
    <div style={{backgroundColor:"#0A273B", width:"100%", height:"auto"}}>
        <h1 className="hero-h1">                                                    
              <span className="hero-text hero-h1">Support Alex</span>          
          </h1>    
      </div>

    <div style={{ margin: "20px", height: "1250px" }}>
      <iframe
        className={donationForm}
        src="https://politics.raisethemoney.com/alexriley?iframe=true"
        width="100%"
        height="auto;"
        scrolling="no"
        seamless="true"
        frameBorder="0"
      />
    </div>
  </Layout>
)

export default Donate

const donationForm = css`
  height: 100%;
`
